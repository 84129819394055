:root {
  --black: #000
}

#logo {
  width: 45;
  height: 45
}

.login-container {
  background: var(--black);
  width: 100%;
  height: 100%;
}

.logo-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px
}

.spinner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner .ball {
  width: 53px;
  height: 53px;
}

.form-background {
  background-color: #333;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.threed{
  background-color: #333;
  width: 80%;
   translateX: (-78px) rotate(-5deg) skewX(-12deg) skew(-8deg,14deg) translateY(-90px)
}

.con {
    width: 80%;
    margin: 0 auto;
    background-color: #2F2F31;
}

.searchContainer {
    width: 80%;
    background-color: #2F2F31;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2.5em;
    margin-bottom: 3em;
    padding: 2em;
    border-radius: 5px;
}
.nav-text {
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
}
.nav-text-dropdown {
  text-decoration: none;
  color: #000;
  font-size: 1rem;
}
.nav-text-dropdown:hover {
  color: #04AA6D
}
.nav-text:hover {
  color: #FFD700
}

.searchDisplay {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5vh;
}

table, input
{
    table-layout:auto;
    width:auto;
}

/* .printContainer {
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 4rem;
} */

.header{
    background-color: #22A86D;
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 25px;
    letter-spacing: 1px;
}
.formRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.formColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrap{
    justify-content: space-evenly;
    padding: 40px 40px 80px ;
    text-align: center;
    margin-top: 27px;
    width: 100%;
}

input {
 color: #000
}

.formColumn input {
    background-color: #424242;
    border-color: transparent;
    display: inline-block;
    height: 53px;
    width: 100%;
    margin-bottom: 27px;
    outline: none;
    color: white;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 0 0 0 45px;
    font-family: "Lato", sans-serif;
}
/* select {
  background-color: #000;
  border-color: transparent;
  display: inline-block;
  height: 53px;
  width: 45%;
  margin-top: 26px;
  margin-bottom: 27px;
  outline: none;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 0 0 0 45px;
  font-family: "Lato", sans-serif;
} */

/* .search input {
  background-color: #424242;
  border-color: transparent;
  display: inline-block;
  margin-bottom: 15px;
  height: 50px;
  width: 45%;
  outline: none;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 0 0 0 45px;
  font-family: "Lato", sans-serif;
} */

/* .search button {
  position: relative;
  border-color: transparent;
  padding: 9px 36px;
  font-family: "Lato", sans-serif;
  color: white;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: bolder;
  background-color: #22A86D;
  cursor: pointer;
} */

.group button{
    position: relative;
    border-color: transparent;
    padding: 9px 36px;
    font-family: "Lato", sans-serif;
    color: white;
    border-radius: 5;
    width: 100%;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: bolder;
    background-color: #22A86D;
    cursor: pointer;

}
.con p {
  float: right;
  margin-bottom: 28px;
  color: #fff
}

.con p:hover {
  color: #ddd
}

.topnav {
  background-color: #04AA6D;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.topnav a {
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 18px 18px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #333;
  color: white;
}

.table {
  width: 80%;
  margin-top: 4rem;
  margin: 0 auto;
}

.selectContainer {
  width: '100%';
  display: flex;
  flex-direction: row;
}

.search {
  margin: 1em;
  width: 40%;
  display: flex;
  float: right;
  flex-direction: row;
  align-items: flex-end;
}

/* FOOTER */
.mini-footer {
  width: 100%;
  background:#192027;
  text-align:center;
  padding:32px 0;
  position: fixed;
  bottom: 0;
 }
 .mini-footer p {
  margin:0;
  line-height:26px;
  font-size:15px;
  color:#999
 }
 .mini-footer p a {
  color:#5867dd;
 }
 .mini-footer p a:hover {
  color:#34bfa3
 }
